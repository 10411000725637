/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Accordion, Badge, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { isNull } from 'lodash';
import { Loader, PageContainer } from '../../shared/components';
import { PrimaryButton } from '../../shared/components/GenericComponent';
import './DocumentSectionConfigPage.scss';
import DocumentConfigTable from '../../shared/components/Table/DocumentConfigTable';
import { camelToNormal, displayToastMessage } from '../../shared/utils/Helper';
import { TOAST_TYPES } from '../../shared/constants/Constants';

const DocumentSectionConfigPage = ({
  isLoading,
  formik,
  materialId,
  materialName,
  doUpdateMaterialsConfigById,
}) => {
  const [activeKey, setActiveKey] = useState('0');
  const [rerenderDataPoints, setRerenderDataPoints] = useState(0);
  const [rerenderDocumentsTable, setRerenderDocumentsTable] = useState(0);

  useEffect(() => {
    formik?.values?.documentConfigDetails?.forEach((section, index) => {
      const toggleOff =
        (section?.documents?.length === 0 ||
          section?.documents?.every((doc) => doc.documentStatus === 'notRequired')) &&
        (section?.dataPoints?.length === 0 ||
          section?.dataPoints?.every((dp) => dp.dataPointStatus === 'notRequired'));

      if (!toggleOff !== section?.sectionStatus) {
        formik.setFieldValue(`documentConfigDetails[${index}].sectionStatus`, !toggleOff);
      }
    });
  }, [formik?.values?.documentConfigDetails]);

  const handleDataPointTableRerender = () => {
    setRerenderDataPoints((prevKey) => prevKey + 1);
  };

  const filterData = (data, isDoc = false) => {
    let rowData = [];
    let serialNumber = 1;
    data?.forEach((item) => {
      let eachRow = {};
      if (isDoc) {
        eachRow = {
          'S.No': serialNumber++,
          Datapoints: item?.documentName,
          status: item?.documentStatus,
        };
      } else {
        eachRow = {
          'S.No': serialNumber++,
          Datapoints: item?.dataPointName,
          'Data Type': camelToNormal(item?.dataType),
          Properties: isNull(item?.properties)
            ? '-'
            : item?.properties?.options?.length
            ? `Options: ${item.properties.options.join(', ')}`
            : item?.properties?.characterLimit
            ? `Character Limit: ${item.properties.characterLimit}`
            : '-',
          status: item?.dataPointStatus,
        };
      }
      rowData = [...rowData, eachRow];
    });

    return rowData;
  };

  const handleSave = async () => {
    const resp = await doUpdateMaterialsConfigById(
      materialId,
      formik?.values?.documentConfigDetails
    );
    if (resp?.status >= 400) {
      displayToastMessage(TOAST_TYPES?.ERROR, resp?.statusText);
    } else {
      displayToastMessage(
        TOAST_TYPES?.SUCCESS,
        `${materialName} Configurations Updated Successfully`
      );
    }
  };

  const handleSelect = (key) => {
    setActiveKey(key === activeKey ? null : key);
  };

  const handleToggleChange = (e, section, index) => {
    const newStatus = e.target.checked ? 'mandatory' : 'notRequired';
    formik.setFieldValue(`documentConfigDetails[${index}].sectionStatus`, e.target.checked);

    section?.documents?.forEach((doc, docIndex) => {
      formik.setFieldValue(
        `documentConfigDetails[${index}].documents[${docIndex}].documentStatus`,
        newStatus
      );
    });

    section?.dataPoints?.forEach((dp, dpIndex) => {
      formik.setFieldValue(
        `documentConfigDetails[${index}].dataPoints[${dpIndex}].dataPointStatus`,
        newStatus
      );
    });

    handleDataPointTableRerender();
    setRerenderDocumentsTable((prev) => prev + 1);
  };
  return (
    <PageContainer style={{ overflowY: 'auto', maxHeight: '100vh' }}>
      <Row className="mb-3">
        <Col md={8}>
          <h2>{materialName}</h2>
        </Col>
        <Col className="d-flex justify-content-end align-items-center gap-1">
          <PrimaryButton
            buttonStyle={{
              borderRadius: '8px',
            }}
            label="Save"
            onClick={handleSave}
            disabled={!formik?.isValid || !formik?.dirty}
          />
        </Col>
      </Row>

      <Accordion activeKey={activeKey} onSelect={handleSelect}>
        {isLoading ? (
          <Row className="justify-content-center" style={{ height: '80vh' }}>
            <Col className="d-flex justify-content-center align-items-center">
              <Loader />
            </Col>
          </Row>
        ) : (
          formik?.values?.documentConfigDetails?.map((section, index) => {
            return (
              <Card className="mt-4" style={{ borderRadius: '10px' }} key={index}>
                <Accordion.Toggle
                  style={{
                    border: '1px solid #EAECF0',
                    background: '#F2F4F7',
                  }}
                  as={Card.Header}
                  eventKey={index.toString()}>
                  <Card.Title className="p-2">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 className="pt-2 mr-3">{section?.sectionName}</h4>
                      </div>
                      <Form.Check
                        type="switch"
                        id={`toggle-${index}`}
                        label="Required"
                        checked={section?.sectionStatus}
                        onChange={(e) => {
                          handleToggleChange(e, section, index);
                        }}
                      />
                    </div>
                  </Card.Title>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={index.toString()}>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col md={8}>
                        <h4>Documents</h4>
                      </Col>
                    </Row>
                    <React.Fragment key={`documents-${rerenderDocumentsTable}`}>
                      <DocumentConfigTable
                        data={filterData(section?.documents, true)}
                        isDocumentsTable
                        formik={formik}
                        secIndex={index}
                        handleRefresh={handleDataPointTableRerender}
                      />
                    </React.Fragment>

                    <Row className="mt-4 mb-3">
                      <Col md={8}>
                        <h4>Data Points</h4>
                      </Col>
                    </Row>
                    <React.Fragment key={`dataPoints-${rerenderDataPoints}`}>
                      <DocumentConfigTable
                        data={filterData(section?.dataPoints)}
                        formik={formik}
                        secIndex={index}
                      />
                    </React.Fragment>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })
        )}
      </Accordion>
    </PageContainer>
  );
};

export default DocumentSectionConfigPage;
