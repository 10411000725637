/* eslint-disable no-nested-ternary */
import * as moment from 'moment';
import {
  keyBy,
  map,
  toLower,
  pickBy,
  mapKeys,
  identity,
  sumBy,
  flatMap,
  isArray,
  mapValues,
  mean,
  omit,
  isEmpty,
  meanBy,
  isUndefined,
  find,
  includes,
} from 'lodash';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { toast, Slide } from 'react-toastify';
import * as paisa from './paisa';
import { AGGREGATOR_DOCUMENTS_SALEORDER, gstCheckDate, TOAST_TYPES } from '../constants/Constants';

const groupBy = (xs, key) => {
  return xs?.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function debounce(func, wait) {
  let timeout;
  return (...args) => {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

/**
 * convert filters to server required filter format
 * @param params
 * @param filterMap
 */
export function transformParams(params, filterMap) {
  const newParams = {};
  map(params, (value, k) => {
    if (isArray(filterMap[k]) && isArray(value)) {
      value.forEach((paramValue, index) => (newParams[filterMap[k][index]] = paramValue));
      return true;
    }
    newParams[filterMap[k]] = value;
    return true;
  });
  return pickBy(newParams, identity);
}

/**
 * @param params
 * @param filterMap
 */
export function transformParamsForAggregator(params, filterMap) {
  const newParams = {};
  map(params, (value, k) => {
    if (isArray(filterMap[k]) && isArray(value)) {
      value.forEach((paramValue, index) => (newParams[filterMap[k][index]] = paramValue));
      return true;
    }
    newParams[filterMap[k]] = value;
    return true;
  });
  return newParams;
}
/**
 * convert array of id,value to object {key:value}
 * @param filterArray
 * @return {*}
 */
export function parseTableFilters(filterArray) {
  return filterArray?.reduce((obj = {}, f) => {
    obj[f.id] = f.value;
    return obj;
  }, {});
}

export function dynamicParseTableFilters(filterArray) {
  return filterArray?.reduce((obj = {}, f) => {
    if (f.value.operation !== undefined && Array.isArray(JSON.parse(f.value.operation))) {
      if (JSON.parse(f.value.operation).length === 1) {
        JSON.parse(f.value.operation).map((e) => {
          obj[f.value.filterParam + e] = f.value.value || undefined;
          return obj;
        });
      } else {
        let count = 0;
        JSON.parse(f.value.operation).forEach((e) => {
          obj[f.value.filterParam + e] = f.value.value[count] || undefined;
          count += 1;
          return obj;
        });
      }
    } else {
      obj[f.value.filterParam] = f.value;
      return obj;
    }
    return obj;
  }, {});
}

/**
 * convert url params to tableFilters
 * @param filterObject
 * @return {unknown[]}
 */
export function convertToTableFilters(filterObject) {
  const _obj = omit(filterObject, ['page', 'size', 'sort']);
  return map(_obj, (v, k) => ({
    id: k,
    value: v,
  }));
}

export function buildUrl(string = '', obj) {
  let s = string;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const prop in obj) {
    s = s.replace(new RegExp(`{${prop}}`, 'g'), obj[prop]);
  }
  return s;
}

function numberWithCommas(x) {
  if (!x) return x;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
/**
 * convert server date to local date format
 * @param dateString
 * @param format
 * @return {string}
 */
const toLocalDate = (dateString, format = 'DD-MMM-YYYY') => {
  if (!dateString) return '';
  return moment(dateString).format(format);
};

const toLocalDateMonth = (dateString, format = 'DD-MM-YYYY') => {
  if (!dateString) return '';
  return moment(dateString).format(format);
};

/**
 * convert server date to local date format
 * @param dateString
 * @param format
 * @return {string}
 */
const toLocalDateTime = (dateString, format = 'DD-MMM-YYYY HH:mm:ss') => {
  if (!dateString) return '';
  return moment(dateString).format(format);
};

const convertToLocalDateTime = (date, format = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') => {
  return moment(date).format(format);
};

const gstPercentageValue = (orderDate) => {
  const newDate = moment(orderDate).format('YYYY-MM-DD');
  if (moment(newDate).isAfter(gstCheckDate)) {
    return 18;
  }
  return 5;
};

const getDateTimeAge = (dateString) => {
  return moment(dateString).fromNow();
};

const getDateDiff = (dateString = '', units) => {
  const data = moment(dateString);
  return moment().diff(data, units || 'days');
};

const getDateAdd = (dataString = '', addUnits, units) => {
  return moment(dataString).add(addUnits, units || 'days');
};

const isExist = (list = [], matchKey, matchValue) => {
  return list?.findIndex((i) => i[matchKey] === matchValue) > -1;
};
/**
 * convert local date to Server required format
 * @param date
 * @param onlyDate
 * @param format
 * @return {string}
 */
const toServerDate = (date, onlyDate, format = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') => {
  const _date = moment(date);
  return onlyDate ? _date.startOf('day').utc().format(format) : _date.utc().format(format);
};

function toCapitalize(str) {
  if (!str) return str;
  let i;
  const frags = str?.split('_');
  for (i = 0; i < frags?.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)?.toLowerCase();
  }
  return frags.join(' ');
}

// TODO make it generic
function deepKeyBy(arr, key = 'id') {
  return keyBy(
    map(arr, (o) => ({
      ...o,
      districts: keyBy(o.districts, key),
    })),
    key
  );
}

function extractError(res) {
  if (res?.data?.message && res?.data?.errorCode && res?.data?.errorType) {
    return res?.data;
  }
  if (res?.headers['x-recykalapp-error']) {
    return {
      message: res.headers['x-recykalapp-error'],
      key: res.headers['x-recykalapp-error-key'],
    };
  }
  if (res?.data?.detail) {
    return {
      message: res?.data?.detail,
    };
  }
  if (res?.data?.message) {
    return res?.data;
  }
  return {
    message: 'Error while processing your request.',
  };
}

function isPickedQtyDocumentsExits(order) {
  let isPickedQty = true;
  order?.items?.forEach((item) => {
    if (item.pickedQty === undefined || item.pickedQty === null || item.pickedQty === 0) {
      isPickedQty = false;
    }
  });

  AGGREGATOR_DOCUMENTS_SALEORDER.forEach((e) => {
    if (order?.logs?.findIndex((log) => log.toStatus === e.status) > 0) {
      if (order?.documents?.filter((doc) => doc?.type === e.type)?.length === 0) {
        isPickedQty = false;
        return '';
      }
    }
    return '';
  });
  return isPickedQty;
}

function hasError(response) {
  if (response?.status >= 400) {
    const error = extractError(response);
    return error;
  }
  return false;
}

const parseStringAddress = async (address, cities) => {
  const addr = address.split(',').filter(Boolean);
  const addressObj = {
    streetAddress: addr.splice(1, addr.length - 3)?.join(','),
    flatBuilding: addr[0],
    zipCode: '',
    latLng: {
      lat: '',
      lng: '',
    },
    city: '',
    state: '',
    region: '',
  };

  const results = await geocodeByAddress(address);
  const latLng = await getLatLng(results[0]);
  const addrComponent = results[0]?.address_components;
  addressObj.city = addrComponent?.find((comp) => comp?.types?.indexOf('locality') >= 0)?.long_name;
  addressObj.zipCode = addrComponent?.find(
    (comp) => comp?.types?.indexOf('postal_code') >= 0
  )?.long_name;

  if (isUndefined(addressObj?.zipCode)) {
    const pincode = address?.split(',');
    addressObj.zipCode = pincode?.[pincode?.length - 1]?.trim();
  }

  addressObj.state = addrComponent?.find(
    (comp) => comp?.types?.indexOf('administrative_area_level_1') >= 0
  )?.long_name;
  const region = cities?.find((city) => city?.pincodes?.includes(addressObj?.zipCode));
  return {
    ...addressObj,
    region,
    latLng,
  };
};

function getAddressTypeFromAddress(address = {}) {
  if (address?.isBilling && address?.isShipping) {
    return 'BOTH';
  }
  if (address?.isBilling) {
    return 'BILLING';
  }
  if (address?.isShipping) {
    return 'SHIPPING';
  }

  if (address?.isPickup) {
    return 'PICKUP';
  }
  return '';
}

/**
 * convert keyBy object to array of object and add item object to it
 * @param orderItems
 * @param items
 * @return {unknown[]}
 */
function getOrderItems(orderItems, items, recycler = {}, qualityPrams = {}, orderLogisticCost = 0) {
  return map(orderItems, (orderItem, k) => {
    // TODO remove it once api added these field
    // orderItem.qualityRiskFactor = 0.5;
    // orderItem.recykalMarginPercentage = 0.86;
    const orderQty = sumBy(
      flatMap(orderItems),
      (it) => orderItem.qty || orderItem.oldQty || orderItem.pickedQty
    );

    orderItem.weightShortage = orderItem?.pickedQty - orderItem?.acceptedQty || 0;
    const item = items ? items?.[orderItem?.itemId] : {};
    const itemQty = orderItem.pickedQty || orderItem.qty || orderItem.oldQty;
    const itemPrice = orderItem?.initialRequisitionPrice || orderItem?.price;
    const lineTotal = itemPrice * itemQty;
    const offerTotal = orderItem?.offerPrice * itemQty;
    const itemTax = percentage(
      orderItem?.isOldOrder ? 5 : item?.sgstPercentage + item?.cgstPercentage,
      lineTotal
    );
    const itemTotal = lineTotal + itemTax;
    const deviations = getDeviations(orderItem, recycler, qualityPrams);
    const deviationPercentage = toFloat(sumBy(deviations, 'deviation')) || 0;

    const deviationQty = sumBy(deviations, 'deviationQty');
    const deviationAmount = sumBy(deviations, 'deviationAmount');
    const deviationPerKg = deviationAmount / itemQty;
    const logisticCost = toFloat((orderLogisticCost * itemQty) / orderQty);
    const qualityRisk = getQualityRisk(offerTotal, orderItem.qualityRiskFactor);
    const recykalMargin = getRecykalMargin(
      offerTotal,
      lineTotal,
      logisticCost,
      orderItem.qualityRiskFactor
    );
    return {
      ...orderItem,
      lineTotal,
      itemTax,
      itemTotal,
      item,
      offerTotal,
      deviations,
      deviationPercentage,
      deviationQty,
      deviationAmount,
      deviationPerKg,
      logisticCost,
      qualityRisk,
      recykalMargin,
    };
  });
}

function getPoOrderItems(orderItems, items, recycler = {}, orderLogisticCost = 0) {
  return map(orderItems, (orderItem, k) => {
    // TODO remove it once api added these field
    // orderItem.qualityRiskFactor = 0.5;
    // orderItem.recykalMarginPercentage = 0.86;
    const orderQty = sumBy(flatMap(orderItems), (it) => orderItem.qty);

    const item = items ? items[orderItem.itemId] : {};
    const itemQty = orderItem.qty;
    const itemPrice = orderItem?.price;
    const lineTotal = itemPrice * itemQty;
    const itemTax = percentage(item?.sgstPercentage + item?.cgstPercentage, lineTotal);
    const itemTotal = lineTotal + itemTax;
    return {
      ...orderItem,
      lineTotal,
      itemTax,
      itemTotal,
      item,
    };
  });
}

function toFloat(value, decimals) {
  return value ? Number(value?.toFixed(decimals || 2)) : 0;
}

function toNumber(value) {
  return value ? Math.abs(value) : 0;
}

export function getQualityRisk(offerTotal, qualityRiskFactor) {
  return percentage(qualityRiskFactor, offerTotal);
}

export function getRecykalMarginPercentage(recykalMargin, offerTotal, logisticCost) {
  return toFloat((recykalMargin * 100) / (offerTotal - logisticCost));
}

function getRecykalMargin(offerTotal, orderTotal, logisticCost, qualityRiskFactor) {
  const qualityRisk = getQualityRisk(offerTotal, qualityRiskFactor);
  return offerTotal - (orderTotal + logisticCost + qualityRisk);
}
/**
 * convert array of items to key by Items
 * @param orderItems
 * @param key
 * @return {Dictionary<unknown>}
 */
function toOrderItems(orderItems, key) {
  return keyBy(orderItems, key || 'id');
}

function getAddressValuesFromAddressType(type) {
  const typeValues = {};
  typeValues.isShipping = ['BOTH', 'SHIPPING'].indexOf(type) > -1;
  typeValues.isBilling = ['BOTH', 'BILLING'].indexOf(type) > -1;
  return typeValues;
}

function getPaidStatus(originalAmount, paidAmount) {
  if (toRupees(originalAmount - paidAmount) <= 1) {
    return true;
  }
  return false;
}

function toRupees(amountInPaisa, withSymbol, decimal) {
  return withSymbol ? paisa.formatWithSymbol(amountInPaisa) : amountInPaisa / 100;
}

function toPaisa(rupees) {
  return rupees * 100;
}

// tds amount calc
function tdsCalculation(amount, tdsPercentage) {
  return (amount / 100) * tdsPercentage;
}

function percentage(percent, total) {
  return Math.round(Number((percent / 100) * total) || 0);
}

const qtyValue = (orderDate, netQty) => {
  // const newDate = moment(orderDate).format('YYYY-MM-DD');
  // if (moment(newDate).isAfter(qtyDate)) {
  //   return netQty / 100;
  // }
  return netQty;
};
function getRecyclerQualityParams(recycler, itemId) {
  const recyclerParams = recycler?.itemDetails && recycler?.itemDetails[itemId]?.qualityParams;
  if (itemId) {
    return (
      mapValues(recyclerParams || {}, (v, k) => ({
        id: k,
        value: v,
      })) || {}
    );
  }
  return recyclerParams;
}

/**
 * @deprecated
 */
function getDeviation(kamAssessments, recyclerItemParam) {
  const kamAssess = map(kamAssessments, (v, id) => ({
    id,
    ...v,
  }));
  const _deviations = kamAssess
    ?.filter(({ id, value }) => recyclerItemParam[id]?.value - value < 0)
    ?.map(({ id, value }) => {
      const recyclerAssess = recyclerItemParam[id] || {};
      const deviation = recyclerAssess.value - value;
      return deviation;
    });
  return Math.abs(Math.round(mean(_deviations))) || 0;
}

function getDeviations(orderItems, recycler, qualityParams) {
  function _getDeviation(orderItem) {
    if (isEmpty(orderItem?.kamAssessments)) return {};
    const recyclerItemParam = getRecyclerQualityParams(recycler, orderItem?.itemId);
    const itemParamsMap = keyBy(
      qualityParams[orderItem?.itemId]?.map((it) => ({
        ...it.param,
        value: it.value,
      })),
      'id'
    );
    const kamAssess = map(orderItem?.kamAssessments, (v, id) => ({
      id,
      ...v,
    }));
    const _deviations = kamAssess
      ?.filter(
        ({ id, value }) =>
          recyclerItemParam[id]?.value >= 0 && recyclerItemParam[id]?.value - value < 0
      )
      ?.map(({ id, value }) => {
        const recyclerAssess = recyclerItemParam[id] || {};
        const deviation = Math.abs(toFloat(recyclerAssess.value - value));
        const deviationQty = percentage(deviation, orderItem.qty);
        const deviationAmount = deviationQty * orderItem?.price;
        return {
          id,
          name: itemParamsMap[id]?.name,
          kamValue: value,
          recyclerValue: recyclerAssess?.value,
          deviation,
          deviationQty,
          deviationAmount,
        };
      });
    return _deviations;
  }
  if (isArray(orderItems)) {
    return orderItems?.map((oItem) => _getDeviation(oItem));
  }
  return _getDeviation(orderItems);
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

const getArialDistance = (p1, p2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(p2.lat2 - p1.lat1); // deg2rad below
  const dLon = deg2rad(p2.lon2 - p1.lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(p1.lat1)) *
      Math.cos(deg2rad(p2.lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
};

function getExtraResponse(response) {
  const totalCount = Number(response?.headers['x-total-count']);
  const data = response?.data;
  return {
    data,
    totalCount,
    response,
  };
}
const hasStatus = (value, statuses) => statuses?.indexOf(value) >= 0;

const hasPOStatus = (value, statuses) => statuses?.indexOf(value) >= 0;

const timeConvert = (n) => {
  const materialDelayTime = moment(toServerDate(new Date()));
  let rhrs = materialDelayTime?.diff(n, 'hours') * -1;
  let rminutes = materialDelayTime?.diff(n, 'minutes') * -1;
  if (rminutes < 0) rminutes = 0;
  if (rhrs < 0) rhrs = 0;
  return `${rhrs}hr and ${rminutes} minutes`;
};

function isValueEmpty(value) {
  return value === '' || value === 'null' || value === undefined || value === null;
}

/**
 * check if the date format is valid or not
 * @param date (String)
 */
const isValidDate = (strDate) => {
  const date = new Date(strDate);
  return Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date.getTime());
};

const FetchSystemUser = (id_, SystemkamUsers) => {
  if (!isEmpty(SystemkamUsers) && id_ !== null && id_ !== undefined && SystemkamUsers?.[id_]) {
    return SystemkamUsers?.[id_];
  }

  return { firstName: 'NA', role: 'NA' };
};
/**
 * round off number to 2 decimal places
 * @param number
 * @param currencyType
 * @return {string}
 */
const roundOffValue = (value, currencyType) => {
  const constantCurrencyType = {
    p: toPaisa(value),
    r: toRupees(value),
  };
  if (constantCurrencyType?.[`${currencyType}`]) {
    return Math.round(parseFloat(Number(constantCurrencyType[`${currencyType}`])));
  }
  return Math.round(parseFloat(Number(value)));
};
const toLocalDateTimeFormat = (value) => {
  return moment(value).format('D-MMM-YYYY, h:mma');
};

/**
 * convert filters to default values when all the other filters are removed
 * @param array
 */
function filtersNotContainsUndefined(arr) {
  return arr?.some((element) => element !== undefined);
}

const splitCapitalize = (val) => toCapitalize(val)?.replace('_', ' ');

const containsNumbers = (str) => /\d/.test(str);

/** Function used to display the toast messages.
 * @params: They would be four params
 * 1. toast type (ie..., success, error, warn or info).
 * 2. message text.
 * 3. time: default 5 seconds or false to manually close it.
 * 4. toast transition type
 */

const displayToastMessage = (type, text, time = 5000, transitionType = Slide) => {
  if (type === TOAST_TYPES?.SUCCESS) {
    toast?.success(text, { transition: transitionType, autoClose: time });
  } else if (type === TOAST_TYPES?.ERROR) {
    toast?.error(text, { transition: transitionType, autoClose: time });
  } else if (type === TOAST_TYPES?.WARN) {
    toast?.warn(text, { transition: transitionType, autoClose: time });
  } else if (type === TOAST_TYPES?.INFO) {
    toast?.info(text, { transition: transitionType, autoClose: time });
  }
};

const calculateNoteAmounts = (notes) => {
  let totalNoteAmount = 0;
  totalNoteAmount = notes?.reduce((acc, noteItems) => {
    const noteAmount = noteItems?.lineItems?.reduce((accItem, item) => {
      const taxableAmt = toRupees(item?.price) * item.qty;
      return accItem + taxableAmt;
    }, 0);
    return acc + noteAmount;
  }, 0);
  return totalNoteAmount;
};

const marginCalculator = (order, currentRecyclerId) => {
  // const orderItems = order?.mpShipmentItems;
  let saleValue = 0;
  let purchaseValue = 0;
  let logisticCost = 0;
  let aggInvoiceRate = 0;

  // const soItemsByRecyclerId = orderItems?.filter(
  //   (items) => items?.recyclerId === currentRecyclerId
  // );
  const soItemsByRecyclerId = order?.mpShipmentItems;
  for (let i = 0; i < soItemsByRecyclerId?.length; i++) {
    const sellerQty =
      soItemsByRecyclerId?.[i]?.dispatchedQuantity || soItemsByRecyclerId?.[i]?.quantity || 0;
    saleValue += percentageCalculation(
      (soItemsByRecyclerId?.[i]?.price || 0) * sellerQty,
      soItemsByRecyclerId?.[i]?.taxPercentage
    );
    aggInvoiceRate += percentageCalculation(
      (soItemsByRecyclerId?.[i]?.sellerInvoicePrice || 0) * sellerQty,
      soItemsByRecyclerId?.[i]?.taxPercentage
    );
    purchaseValue += percentageCalculation(
      (soItemsByRecyclerId?.[i]?.sellerPrice || 0) * sellerQty,
      soItemsByRecyclerId?.[i]?.taxPercentage
    );
  }
  const creditNote =
    order?.buyerDebitNote?.length > 0
      ? calculateNoteAmounts(order?.buyerDebitNote?.filter((note) => note?.noteType === 'CREDIT'))
      : 0;
  const buyerDebitNote =
    order?.buyerDebitNote?.length > 0
      ? calculateNoteAmounts(order?.buyerDebitNote?.filter((note) => note?.noteType === 'DEBIT'))
      : 0;
  const DebitNote =
    order?.sellerDebitNote?.length > 0 ? calculateNoteAmounts(order?.sellerDebitNote) : 0;
  logisticCost = order?.logisticCost || 0;

  return {
    saleValue,
    purchaseValue,
    logisticCost,
    creditNote,
    DebitNote,
    aggInvoiceRate,
    buyerDebitNote,
  };
};

const recykalMargin = (order) => {
  const orderItems = order?.items;
  let saleValue = 0;
  let purchaseValue = 0;
  let logisticCost = 0;
  for (let i = 0; i < orderItems?.length; i++) {
    const sellerQty = orderItems[i]?.dispatchedQuantity || orderItems[i]?.quantity;
    saleValue += percentageCalculation(
      toRupees(orderItems?.[i]?.price) * sellerQty,
      orderItems?.[i]?.itemGst
    );
    purchaseValue += percentageCalculation(
      toRupees(orderItems?.[i]?.sellerPrice) * sellerQty,
      orderItems?.[i]?.itemGst
    );
  }
  logisticCost = toRupees(order?.logisticCost);
  return saleValue - purchaseValue - logisticCost || 0;
};

const percentageCalculation = (totalValue, percentageValue) => {
  return Number(totalValue.toFixed(2));
};

const toNextDate = (dateString, format = 'DD-MMM-YYYY') => {
  if (!dateString) {
    return '';
  }
  const dateObject = new Date(dateString);
  dateObject.setHours(dateObject.getHours() + 5);
  dateObject.setMinutes(dateObject.getMinutes() + 30);
  return moment(dateObject).format(format);
};

const StringWithCommaSeparatedPayload = (payloadObject) => {
  const allDataOptionsArray = payloadObject?.map((option) => {
    return option?.id;
  });
  let eachDataOption = '';
  const concatenatedOptions = allDataOptionsArray?.map((option) => {
    eachDataOption = eachDataOption.concat(option, ',');
    return eachDataOption;
  });

  const lastOptionInArray = concatenatedOptions.pop();
  return lastOptionInArray;
};

const StringNameWithCommaSeparatedPayload = (payloadObject) => {
  const allDataOptionsArray = payloadObject?.map((option) => {
    return option?.name;
  });
  let eachDataOption = '';
  const concatenatedOptions = allDataOptionsArray?.map((option) => {
    eachDataOption = eachDataOption.concat(option, ',');
    return eachDataOption;
  });

  const lastOptionInArray = concatenatedOptions.pop();
  return lastOptionInArray;
};

const isIncludedAllMaterialType = (currentAccount, type = 'All') => {
  const splitAccountMaterials = currentAccount?.category?.split(',');
  const includedAllMaterialType = includes(splitAccountMaterials, type);
  return includedAllMaterialType;
};

// deriving pan from gstin
function extractPANNumberFromGSTIN(str) {
  let panNumber = '';
  if (str?.length > 0 && str?.length <= 15) {
    panNumber = str?.substring(2, str?.length - 3);
  }
  return panNumber;
}

export const fetchHsnCodeByItemId = (itemId, items) => {
  return items?.find((item) => item?.id === itemId)?.hsn;
};

export const capitalizeWordsInString = (inputString) => {
  return inputString
    ?.split(' ')
    ?.map((string) => string?.charAt(0)?.toUpperCase() + string?.slice(1).toLowerCase())
    ?.join(' ');
};

export const resellOrderNetMargin = (order) => {
  const orderItems = order?.items;
  let saleValue = 0;
  let purchaseValue = 0;
  let logisticCost = 0;
  let aggInvoiceRate = 0;
  for (let i = 0; i < orderItems?.length; i++) {
    const sellerQty = orderItems?.[i]?.pickedQty || orderItems?.[i]?.qty;
    saleValue += percentageCalculation(
      toRupees(orderItems?.[i]?.offerPrice) * sellerQty,
      orderItems?.[i]?.itemGst
    );
    aggInvoiceRate += percentageCalculation(
      toRupees(orderItems?.[i]?.aggInvoiceRate) * sellerQty,
      orderItems?.[i]?.itemGst
    );
    purchaseValue += percentageCalculation(
      toRupees(orderItems?.[i]?.recyclerId === order?.recyclerId && orderItems?.[i]?.price) *
        sellerQty,
      orderItems?.[i]?.itemGst
    );
  }
  const creditNote =
    order?.buyerDebitNote?.length > 0
      ? calculateNoteAmounts(order?.buyerDebitNote?.filter((note) => note?.noteType === 'CREDIT'))
      : 0;
  const buyerDebitNote =
    order?.buyerDebitNote?.length > 0
      ? calculateNoteAmounts(order?.buyerDebitNote?.filter((note) => note?.noteType === 'DEBIT'))
      : 0;
  const DebitNote =
    order?.sellerDebitNote?.length > 0 ? calculateNoteAmounts(order?.sellerDebitNote) : 0;
  logisticCost = order?.logisticCost;

  const totalNetMargin =
    saleValue - purchaseValue - logisticCost - creditNote + buyerDebitNote + DebitNote || 0;
  return totalNetMargin;
};

const camelToNormal = (str) => {
  const result = str?.replace(/([a-z])([A-Z])/g, '$1 $2')?.toLowerCase();
  return result?.charAt(0)?.toUpperCase() + result?.slice(1);
};

function formatNumberToIndianSystem(amount, decimalPlaces = 2) {
  if (typeof amount === 'string') {
    Number.isInteger(amount) ? (amount = parseInt(amount)) : (amount = parseFloat(amount));
    if (Number.isNaN(amount)) return '-';
  }
  // Format number using toLocaleString with Indian locale and applying decimal
  return amount?.toLocaleString('en-IN', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

export const calculateNetMarginPercentage = (order, recyclerId) => {
  const TransactionDetails = marginCalculator(order, recyclerId);
  const totalNetMargin =
    TransactionDetails?.saleValue -
      TransactionDetails?.purchaseValue -
      TransactionDetails?.logisticCost -
      TransactionDetails?.creditNote +
      TransactionDetails?.buyerDebitNote +
      TransactionDetails?.DebitNote || 0;
  const percentageValue = (totalNetMargin / TransactionDetails?.saleValue) * 100 || 0;
  return percentageValue.toFixed(2);
};

export {
  groupBy,
  toLocalDate,
  toLocalDateTime,
  toLocalDateMonth,
  convertToLocalDateTime,
  toServerDate,
  toCapitalize,
  numberWithCommas,
  deepKeyBy,
  extractError,
  hasError,
  parseStringAddress,
  getAddressTypeFromAddress,
  getAddressValuesFromAddressType,
  getOrderItems,
  toOrderItems,
  toRupees,
  toPaisa,
  getDateTimeAge,
  percentage,
  qtyValue,
  hasStatus,
  getDeviation,
  getRecyclerQualityParams,
  getExtraResponse,
  getRecykalMargin,
  toNumber,
  toFloat,
  getDateDiff,
  getDateAdd,
  isExist,
  getArialDistance,
  isPickedQtyDocumentsExits,
  hasPOStatus,
  getPaidStatus,
  getPoOrderItems,
  timeConvert,
  tdsCalculation,
  gstPercentageValue,
  isValidDate,
  isValueEmpty,
  FetchSystemUser,
  roundOffValue,
  toLocalDateTimeFormat,
  filtersNotContainsUndefined,
  splitCapitalize,
  displayToastMessage,
  containsNumbers,
  marginCalculator,
  percentageCalculation,
  toNextDate,
  recykalMargin,
  StringWithCommaSeparatedPayload,
  StringNameWithCommaSeparatedPayload,
  isIncludedAllMaterialType,
  extractPANNumberFromGSTIN,
  formatNumberToIndianSystem,
  camelToNormal,
};
