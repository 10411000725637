/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal } from 'react-bootstrap';
import { camelCase, isNaN, isNull } from 'lodash';
import { FormField } from '../../shared/components';
import {
  AlphaNumeric,
  CheckboxIcon,
  MultiSelectDropDown,
  NumericType,
  RadioIcon,
  SingleSelectDropDown,
  TimeAndDate,
  TrashIcon,
} from '../../assets/img/imageExport/images';
import './AddDataPointModal.scss';
import { DoShow, PrimaryButton } from '../../shared/components/GenericComponent';
import { toLocalDateTime } from '../../shared/utils/Helper';

const AddDataPointModal = ({
  showDataPointModal,
  setShowDataPointModal,
  sectionIndex,
  setSectionIndex,
  dataPointIndex,
  setDataPointIndex,
  formik,
  defaultKeys,
}) => {
  const [dataPointName, setDataPointName] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [characterLimit, setCharacterLimit] = useState(null);
  const [optionsList, setOptionsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const options = [
    { label: 'Numeric', icon: <NumericType />, value: 'numericType' },
    { label: 'Alpha Numeric', icon: <AlphaNumeric />, value: 'alphaNumericType' },
    { label: 'Radio Button', icon: <RadioIcon />, value: 'radioButtonType' },
    { label: 'Checkbox', icon: <CheckboxIcon />, value: 'checkBoxType' },
    { label: 'Date', icon: <TimeAndDate />, value: 'dateType' },
    {
      label: 'Single Select Dropdown',
      icon: <SingleSelectDropDown />,
      value: 'singleSelectDropDown',
    },
  ];
  useEffect(() => {
    if (!isNull(dataPointIndex)) {
      setDataPointName(
        formik?.values?.documentConfigDetails?.[sectionIndex]?.dataPoints?.[dataPointIndex]
          ?.dataPointName
      );
      setSelectedOption(
        options.find(
          (option) =>
            option.label ===
            formik?.values?.documentConfigDetails?.[sectionIndex]?.dataPoints?.[dataPointIndex]
              ?.properties?.label
        )
      );
      setOptionsList(
        formik?.values?.documentConfigDetails?.[sectionIndex]?.dataPoints?.[dataPointIndex]
          ?.properties?.options || []
      );
      setCharacterLimit(
        formik?.values?.documentConfigDetails?.[sectionIndex]?.dataPoints?.[dataPointIndex]
          ?.properties?.characterLimit || null
      );
    }
  }, [dataPointIndex]);

  const handleAddOption = () => {
    setOptionsList([...optionsList, '']);
  };

  const handleCancel = () => {
    setErrorMessage('');
    setDataPointName('');
    setSelectedOption(null);
    setCharacterLimit(null);
    setShowDataPointModal(false);
    setSectionIndex(null);
    setOptionsList([]);
    setDataPointIndex(null);
  };

  const handleSaveDataPoint = () => {
    if (isNull(dataPointIndex)) {
      const trimmedDataPointName = dataPointName.trim();
      const dataPointEnum = camelCase(trimmedDataPointName);

      if (defaultKeys.includes(dataPointEnum)) {
        setErrorMessage(
          'DataPoint name conflicts with existing keys. Please choose a different name.'
        );
        return;
      }

      setErrorMessage('');

      const properties = {
        label: selectedOption?.label,
        ...(selectedOption?.value === 'dateType'
          ? null
          : selectedOption?.value === 'numericType' || selectedOption?.value === 'alphaNumericType'
          ? { characterLimit: parseInt(characterLimit, 10) }
          : selectedOption?.value === 'radioButtonType' ||
            selectedOption?.value === 'checkBoxType' ||
            selectedOption?.value === 'singleSelectDropDown'
          ? { options: optionsList.map((option) => option.trim()) }
          : null),
      };

      const newDataPoint = {
        dataPointName: trimmedDataPointName,
        dataPointEnum,
        dataPointId: Number(toLocalDateTime(new Date(), 'DDMMYYYYhhmmssms')),
        dataType: selectedOption?.value,
        properties,
        dataPointStatus: 'notRequired',
        isDefault: true,
      };

      const newDataPointList = [
        ...formik?.values?.documentConfigDetails?.[sectionIndex]?.dataPoints,
        newDataPoint,
      ];

      formik?.setFieldValue(`documentConfigDetails[${sectionIndex}].dataPoints`, newDataPointList);
    } else {
      const properties = {
        label: selectedOption?.label,
        ...(selectedOption?.value === 'dateType'
          ? null
          : selectedOption?.value === 'numericType' || selectedOption?.value === 'alphaNumericType'
          ? { characterLimit: parseInt(characterLimit, 10) }
          : selectedOption?.value === 'radioButtonType' ||
            selectedOption?.value === 'checkBoxType' ||
            selectedOption?.value === 'singleSelectDropDown'
          ? { options: optionsList.map((option) => option.trim()) }
          : null),
      };

      const newDataPoint = {
        ...formik?.values?.documentConfigDetails?.[sectionIndex]?.dataPoints?.[dataPointIndex],
        dataPointName: dataPointName?.trim(),
        dataType: selectedOption?.value,
        properties,
      };
      formik?.setFieldValue(
        `documentConfigDetails[${sectionIndex}].dataPoints[${dataPointIndex}]`,
        newDataPoint
      );
    }

    setShowDataPointModal(false);
    setDataPointName('');
    setSelectedOption(null);
    setCharacterLimit(null);
    setOptionsList([]);
    setSectionIndex(null);
    setDataPointIndex(null);
  };

  const onOptionSelect = (value) => {
    setOptionsList([]);
    // setCharacterLimit(null);
    setSelectedOption(value);
  };

  return (
    <Modal
      className="AddDataPointModal"
      centered
      size="lg"
      show={showDataPointModal}
      onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{isNull(dataPointIndex) ? 'Add Data Point' : 'Edit Data Point'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="m-4">
          <Form>
            <Form.Row>
              <Form.Group as={Col} md={8}>
                <Form.Label>Datapoint Name</Form.Label>
                <FormField>
                  <Form.Control
                    type="text"
                    placeholder="Enter datapoint name"
                    value={dataPointName}
                    onChange={(e) => setDataPointName(e.target.value)}
                  />
                </FormField>
                {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={8}>
                <Form.Label>Data Type</Form.Label>
                <FormField>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" className="dropdown-toggle">
                      {selectedOption ? (
                        <>
                          <span className="p-2" style={{ gap: '10px' }}>
                            {selectedOption.icon}
                            <span className="pt-1 pl-2">{selectedOption.label}</span>
                          </span>
                        </>
                      ) : (
                        'Select Data Type'
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ width: '100%' }}>
                      {options.map((option, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => onOptionSelect(option)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            padding: '8px 12px',
                          }}>
                          {option.icon}
                          <span>{option.label}</span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </FormField>
              </Form.Group>
            </Form.Row>
            <DoShow show={!isNull(selectedOption)}>
              <hr />
            </DoShow>
            <Form.Row>
              <Form.Group as={Col} md={6}>
                {(selectedOption?.value === 'numericType' ||
                  selectedOption?.value === 'alphaNumericType') && (
                  <div>
                    <Form.Label>Character Limit</Form.Label>
                    <FormField>
                      <Form.Control
                        type="Number"
                        placeholder="Enter character limit"
                        value={characterLimit}
                        onChange={(e) => setCharacterLimit(e.target.value)}
                      />
                    </FormField>
                  </div>
                )}
                {(selectedOption?.value === 'radioButtonType' ||
                  selectedOption?.value === 'checkBoxType' ||
                  selectedOption?.value === 'singleSelectDropDown') && (
                  <div>
                    {optionsList.map((option, index) => (
                      <div
                        key={index}
                        className="mb-3"
                        style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FormField style={{ flexGrow: 1 }}>
                          <Form.Control
                            type="text"
                            value={option}
                            placeholder={`Enter option ${index + 1}`}
                            onChange={(e) => {
                              const updatedOptions = [...optionsList];
                              updatedOptions[index] = e.target.value;
                              setOptionsList(updatedOptions);
                            }}
                          />
                        </FormField>
                        <TrashIcon
                          onClick={() => {
                            const updatedOptions = optionsList.filter((_, i) => i !== index);
                            setOptionsList(updatedOptions);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    ))}
                    <PrimaryButton
                      label="+ Add Option"
                      onClick={handleAddOption}
                      buttonStyle={{
                        color: '#156751',
                        border: '1px solid #73A79A',
                        backgroundColor: 'transparent',
                      }}
                    />
                  </div>
                )}
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ borderRadius: '8px' }}
          className="border"
          variant="light"
          onClick={handleCancel}>
          Cancel
        </Button>
        <PrimaryButton
          buttonStyle={{
            borderRadius: '8px',
          }}
          label="Save"
          onClick={handleSaveDataPoint}
          disabled={
            !dataPointName?.trim() ||
            !selectedOption ||
            ((selectedOption?.value === 'numericType' ||
              selectedOption?.value === 'alphaNumericType') &&
              !/^\d+$/.test(characterLimit || '')) ||
            ((selectedOption?.value === 'radioButtonType' ||
              selectedOption?.value === 'checkBoxType' ||
              selectedOption?.value === 'singleSelectDropDown') &&
              (optionsList?.length === 0 || optionsList?.some((option) => !option?.trim())))
          }
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AddDataPointModal;
