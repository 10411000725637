/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { isNull } from 'lodash';
import { Loader, PageContainer } from '../../shared/components';
import { EditGrey, EncircledPlus } from '../../assets/img/imageExport/images';
import { DoShow, PrimaryButton } from '../../shared/components/GenericComponent';
import DocumentConfigTable from '../../shared/components/Table/DocumentConfigTable';
import { camelToNormal, displayToastMessage, toCapitalize } from '../../shared/utils/Helper';
import AddSectionModal from './AddSectionModal';
import AddDocumentModal from './AddDocumentModal';
import AddDataPointModal from './AddDataPointModal';
import { DEFAULT_SHIPMENT_KEYS, TOAST_TYPES } from '../../shared/constants/Constants';

const DocumentSectionMasterPage = ({ formik, doPostDocumentsMasterSection, isLoading }) => {
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showDataPointModal, setShowDataPointModal] = useState(false);

  const [sectionIndex, setSectionIndex] = useState(null);
  const [documentAndDataPointIndex, setDocumentAndDataPointIndex] = useState(null);

  const enumGenerator = (inputString) => {
    return inputString
      .trim() // Remove extra spaces at the start and end
      .toUpperCase() // Convert the string to uppercase
      .replace(/[^a-zA-Z0-9]/g, '_'); // Replace special chars with underscores
  };

  const filterData = (data, isDoc = false) => {
    let rowData = [];
    let serialNumber = 1;
    data?.forEach((item) => {
      let eachRow = {};
      if (isDoc) {
        eachRow = { 'S.No': serialNumber++, Datapoints: item?.documentName };
      } else {
        eachRow = {
          'S.No': serialNumber++,
          Datapoints: item?.dataPointName,
          'Data Type': camelToNormal(item?.dataType),
          Properties: isNull(item?.properties)
            ? '-'
            : item?.properties?.options?.length
            ? `Options: ${item.properties.options.join(', ')}`
            : item?.properties?.characterLimit
            ? `Character Limit: ${item.properties.characterLimit}`
            : '-',
        };
      }
      rowData = [...rowData, eachRow];
    });

    return rowData;
  };

  const handleSave = async () => {
    const resp = await doPostDocumentsMasterSection(formik?.values?.documentConfigDetails);
    if (resp?.status >= 400) {
      displayToastMessage(TOAST_TYPES?.ERROR, resp?.statusText);
    } else {
      displayToastMessage(TOAST_TYPES?.SUCCESS, 'Document Section Master Updated Successfully');
    }
  };

  return (
    <PageContainer style={{ overflowY: 'auto', maxHeight: '100vh' }}>
      <Row>
        <Col md={8}>
          <h3>Document Section Master</h3>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '0.5rem',
          }}>
          <PrimaryButton
            onClick={() => {
              setShowSectionModal(true);
            }}
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                <EncircledPlus />
                Add Section
              </div>
            }
            buttonStyle={{
              display: 'flex',
              borderRadius: '8px',
              color: '#156751',
              border: '1px solid #73A79A',
              backgroundColor: 'transparent',
            }}
          />

          <PrimaryButton
            buttonStyle={{
              borderRadius: '8px',
            }}
            label="Save"
            onClick={handleSave}
            disabled={!formik?.isValid || !formik?.dirty}
          />
        </Col>
      </Row>

      {isLoading ? (
        <Row className="justify-content-center" style={{ height: '80vh' }}>
          <Col className="d-flex justify-content-center align-items-center">
            <Loader />
          </Col>
        </Row>
      ) : (
        formik?.values?.documentConfigDetails?.map((item, index) => (
          <Card className="mt-4" style={{ backgroundColor: '#F1F3F4', borderRadius: '10px' }}>
            <Card.Header
              style={{
                border: '1px solid #EAECF0',
                background: '#F2F4F7',
              }}>
              <Card.Title className="pt-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4>{item?.sectionName}</h4>
                  <DoShow show={item?.isDefault}>
                    <EditGrey
                      style={{ marginLeft: '1.5rem' }}
                      onClick={() => {
                        setSectionIndex(index);
                        setShowSectionModal(true);
                      }}
                    />
                  </DoShow>
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{
                border: '1px solid #EAECF0',
                background: '#F9FAFB',
              }}>
              <Row className="mb-3">
                <Col md={8}>
                  <h4>Documents</h4>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <PrimaryButton
                    onClick={() => {
                      setSectionIndex(index);
                      setShowDocumentModal(true);
                    }}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                        <EncircledPlus />
                        Add Document
                      </div>
                    }
                    buttonStyle={{
                      color: '#156751',
                      borderRadius: '8px',
                      border: '1px solid #73A79A',
                      backgroundColor: 'transparent',
                    }}
                  />
                </Col>
              </Row>
              <DocumentConfigTable
                data={filterData(item?.documents, true)}
                isMaster
                isDocumentsTable
                setDocumentAndDataPointIndex={setDocumentAndDataPointIndex}
                setShowDataPointModal={setShowDataPointModal}
                setShowDocumentModal={setShowDocumentModal}
                setSectionIndex={setSectionIndex}
                secIndex={index}
                formik={formik}
              />

              <Row className="mt-4 mb-3">
                <Col md={8}>
                  <h4>Data Points</h4>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <PrimaryButton
                    onClick={() => {
                      setSectionIndex(index);
                      setShowDataPointModal(true);
                    }}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                        <EncircledPlus />
                        Add Datapoints
                      </div>
                    }
                    buttonStyle={{
                      color: '#156751',
                      borderRadius: '8px',
                      border: '1px solid #73A79A',
                      backgroundColor: 'transparent',
                    }}
                  />
                </Col>
              </Row>
              <DocumentConfigTable
                data={filterData(item?.dataPoints)}
                isMaster
                setDocumentAndDataPointIndex={setDocumentAndDataPointIndex}
                setShowDataPointModal={setShowDataPointModal}
                setShowDocumentModal={setShowDocumentModal}
                setSectionIndex={setSectionIndex}
                secIndex={index}
                formik={formik}
              />
            </Card.Body>
          </Card>
        ))
      )}

      <AddSectionModal
        showSectionModal={showSectionModal}
        setShowSectionModal={setShowSectionModal}
        formik={formik}
        editIndex={sectionIndex}
        setEditIndex={setSectionIndex}
        enumGenerator={enumGenerator}
        defaultKeys={DEFAULT_SHIPMENT_KEYS}
      />
      <AddDocumentModal
        showDocumentModal={showDocumentModal}
        setShowDocumentModal={setShowDocumentModal}
        sectionIndex={sectionIndex}
        setSectionIndex={setSectionIndex}
        formik={formik}
        documentIndex={documentAndDataPointIndex}
        setDocumentIndex={setDocumentAndDataPointIndex}
        enumGenerator={enumGenerator}
        defaultKeys={DEFAULT_SHIPMENT_KEYS}
      />
      <AddDataPointModal
        showDataPointModal={showDataPointModal}
        setShowDataPointModal={setShowDataPointModal}
        sectionIndex={sectionIndex}
        setSectionIndex={setSectionIndex}
        formik={formik}
        dataPointIndex={documentAndDataPointIndex}
        setDataPointIndex={setDocumentAndDataPointIndex}
        defaultKeys={DEFAULT_SHIPMENT_KEYS}
      />
    </PageContainer>
  );
};

export default DocumentSectionMasterPage;
