import React, { useEffect, useState } from 'react';

import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import styled from 'styled-components';
import moment from 'moment';

import './SegmentationPage.scss';
import AddIcon from '../../assets/img/Add_Item.svg';
import { PageContainer } from '../../shared/components';
import { PrimaryButton } from '../../shared/components/GenericComponent';
import { PAGE_CONFIG } from '../../shared/constants/Constants';
import SegmentationModel from '../../features/Segmentation/SegmentationModel/SegmentationModel';

import { useCatalog } from '../../hooks';
import { useSegmentation } from '../../hooks/hooks';
import SegmentationTable from './SegmentationTable';
import { DefaultColumnFilter, DropdownFilter } from '../../shared/components/FilterComponents';
import {
  extendTypesValues,
  extractAppliedConditionsOn,
  extractedMaterialType,
} from '../../features/Segmentation/SegmentUtils';
import SegmentsLogs from '../../features/Segmentation/SegmentsLogs/SegmentsLogs';
import HasRolePermission from '../../shared/HasRolePermission';
import { ALL_MODULES } from '../../shared/constants/Modules';
import { ALL_PERMISSIONS } from '../../shared/constants/Permissions';

const StyledH5 = styled.h5`
  font-size: 14px;
  font-weight: lighter;
  font-family: font-medium;
  text-align: left;
  width: 100%;
  padding: 2px;
`;

const SegmentationPage = () => {
  const [show, setShow] = useState(false);
  const [logsShow, setLogsShow] = useState(false);
  const [getData, setData] = useState([]);
  const [getLogs, setLogsData] = useState([]);
  const [editData, setEditData] = useState(false);
  const [renderApi, setUpdateApi] = useState(false);
  const { materialTypes = [], activeSellerTypes = [] } = useCatalog();

  const queryParamsConfig = {
    materialType: withDefault(StringParam, undefined),
    aggregatorType: withDefault(StringParam, undefined),
    segmentName: withDefault(StringParam, undefined),
    appliedConditionsOn: withDefault(StringParam, undefined),
    sort: withDefault(StringParam, 'createdAt,desc'),
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, PAGE_CONFIG?.size),
  };
  const [filters, setFilters] = useQueryParams(queryParamsConfig);
  const [{ error, loading, value }, { doGetVendorSegmentation }] = useSegmentation();
  const [
    { error: errorLogs, loading: loadingLogs, value: valueLogs },
    { doGetLogsSegmentation },
  ] = useSegmentation();

  const ApiCall = (queryParams) => {
    doGetVendorSegmentation(queryParams);
  };

  useEffect(() => {
    setLogsData(valueLogs);
  }, [valueLogs]);
  // useEffect(() => {
  //   ApiCall(filters);
  // }, [renderApi]);

  useEffect(() => {
    setData(value?.data);
  }, [value]);

  const getVendorSegment = (vendorSegmentValues) => {
    return vendorSegmentValues?.map((item) => item?.segmentName).join(',  ');
  };

  const columns = React.useMemo(
    () => [
      {
        id: 'materialType',
        Header: 'Vertical Type',
        accessor: 'materialType',
        Filter: DropdownFilter,
        filterOptions: extractedMaterialType(materialTypes),
        canSort: true,
        Cell: (cell) => (
          <StyledH5 style={{ fontWeight: 'normal' }}>{cell?.row?.original?.materialType}</StyledH5>
        ),
      },
      {
        id: 'aggregatorType',
        Header: 'Vendor Type',
        accessor: 'aggregatorType',
        Filter: DropdownFilter,
        filterOptions: extendTypesValues(activeSellerTypes),
        Cell: (cell) => <StyledH5>{cell?.row?.original?.aggregatorType}</StyledH5>,
      },
      {
        id: 'appliedConditionsOn',
        Header: 'Conditions Applied',
        accessor: 'appliedConditionsOn',
        Filter: DropdownFilter,
        filterOptions: extractAppliedConditionsOn(),
        Cell: (cell) => {
          const appliedConditionsOn = JSON.parse(cell?.row?.original?.appliedConditionsOn);
          const Conditions = appliedConditionsOn
            ?.flatMap((item) => Object.values(item))
            ?.join(', ');
          return <StyledH5>{Conditions}</StyledH5>;
        },
      },
      {
        id: 'segmentName',
        Header: 'Segmentation',
        accessor: 'segmentation',
        Filter: DefaultColumnFilter,
        Cell: (cell) => {
          return <StyledH5>{getVendorSegment(cell?.row?.original?.vendorSegmentValues)}</StyledH5>;
        },
      },
      {
        id: 'createdAt',
        Header: 'created By',
        accessor: 'createdAt',
        Cell: (cell) => {
          return (
            <>
              <StyledH5 className="pb-0">
                {cell?.row?.original?.createdUser}
                <br />
                {moment(new Date(cell?.row?.original?.createdAt)).format('DD-MMM-YYYY')}
              </StyledH5>
            </>
          );
        },
      },
      {
        id: 'Action',
        Header: 'Action',
        accessor: 'action',
        Cell: (cell) => {
          return (
            <div style={{ display: 'flex', alignSelf: 'center' }}>
              <span
                onClick={() => {
                  doGetLogsSegmentation(cell?.row?.original?.id);
                  hideLogsModal();
                }}
                role="button"
                tabIndex="0"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    console.log('onClick');
                  }
                }}
                style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '14px' }}>
                Logs
              </span>
              <HasRolePermission
                moduleName={ALL_MODULES.SEGMENTATION}
                permissionName={ALL_PERMISSIONS.SEGMENTATION_EDIT}>
                <span className="mx-2 align-content-center">|</span>
                <span
                  onClick={() => {
                    setEditData(cell?.row?.original);
                    setShow(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      console.log('onClick');
                    }
                  }}
                  role="button"
                  tabIndex="0"
                  style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '14px' }}>
                  Edit
                </span>
              </HasRolePermission>
            </div>
          );
        },
      },
    ],
    []
  );

  /**
   * Toggles the visibility of the modal.
   *
   * @return {void}
   */
  const hideModal = () => {
    setEditData(false);
    setShow((prev) => !prev);
  };

  const hideLogsModal = () => {
    setLogsShow((prev) => !prev);
  };

  const handleFilterUpdate = async (filter) => {
    setFilters(filter, 'replace');
    await ApiCall(filter);
  };

  return (
    <PageContainer>
      <div className="d-flex-container d-flex justify-content-between">
        <div>
          <h1>Segmentation</h1>
          <h5>Manage and group users into segments to define personalized rules for them</h5>
        </div>
        <HasRolePermission
          moduleName={ALL_MODULES.SEGMENTATION}
          permissionName={ALL_PERMISSIONS.SEGMENTATION_CREATE}>
          <PrimaryButton
            buttonStyle={{ alignSelf: 'center' }}
            label="Add Segmentation"
            src={AddIcon}
            onClick={hideModal}
            buttonClassName="primary-button"
          />
        </HasRolePermission>
      </div>
      <hr />
      <SegmentationModel
        showState={hideModal}
        show={show}
        editData={editData}
        setUpdateApi={setUpdateApi}
        renderApi={renderApi}
      />
      <SegmentsLogs showState={hideLogsModal} show={logsShow} logs={getLogs} />
      <SegmentationTable
        columns={columns}
        data={getData}
        onFilterUpdate={handleFilterUpdate}
        queryParamsConfig={queryParamsConfig}
        setFilters={setFilters}
        totalCount={value?.totalCount}
        filters={filters}
      />
    </PageContainer>
  );
};

export default SegmentationPage;
