import React, { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { FormField } from '../../shared/components';
import { PrimaryButton } from '../../shared/components/GenericComponent';
import { toLocalDateTime } from '../../shared/utils/Helper';

const AddDocumentModal = ({
  formik,
  showDocumentModal,
  setShowDocumentModal,
  sectionIndex,
  setSectionIndex,
  documentIndex,
  setDocumentIndex,
  enumGenerator = () => {},
  defaultKeys,
}) => {
  const [documentName, setDocumentName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!isNull(documentIndex)) {
      setDocumentName(
        formik?.values?.documentConfigDetails?.[sectionIndex]?.documents?.[documentIndex]
          ?.documentName
      );
    } else {
      setDocumentName('');
    }
  }, [documentIndex]);

  const handleSaveDocument = () => {
    if (isNull(documentIndex)) {
      const trimmedDocumentName = documentName.trim();
      const documentEnum = enumGenerator(trimmedDocumentName);

      if (defaultKeys.includes(documentEnum)) {
        setErrorMessage(
          'Document name conflicts with existing keys. Please choose a different name.'
        );
        return;
      }

      setErrorMessage('');
      const newDocument = {
        documentName: trimmedDocumentName,
        documentId: Number(toLocalDateTime(new Date(), 'DDMMYYYYhhmmssms')),
        documentEnum,
        documentStatus: 'notRequired',
        isDefault: true,
      };
      const newDocList = [
        ...formik?.values?.documentConfigDetails?.[sectionIndex]?.documents,
        newDocument,
      ];
      formik.setFieldValue(`documentConfigDetails[${sectionIndex}].documents`, newDocList);
    } else {
      const newDocList = [...formik?.values?.documentConfigDetails?.[sectionIndex]?.documents];
      newDocList[documentIndex] = {
        ...newDocList[documentIndex],
        documentName: documentName.trim(),
      };

      formik.setFieldValue(`documentConfigDetails[${sectionIndex}].documents`, newDocList);
    }
    setDocumentIndex(null);
    setDocumentName('');
    setSectionIndex(null);
    setShowDocumentModal(false);
  };
  const handleCancel = () => {
    setErrorMessage('');
    setDocumentIndex(null);
    setDocumentName('');
    setSectionIndex(null);
    setShowDocumentModal(false);
  };
  return (
    <Modal centered size="lg" show={showDocumentModal} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{isNull(documentIndex) ? 'Add Document' : 'Edit Document'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="m-4">
          <Form>
            <Form.Row>
              <Form.Group as={Col} md={12}>
                <Form.Label>Document Name</Form.Label>
                <FormField>
                  <Form.Control
                    type="text"
                    placeholder="Enter Document name"
                    value={documentName}
                    onChange={(e) => {
                      setDocumentName(e.target.value);
                    }}
                  />
                </FormField>
                {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ borderRadius: '8px' }}
          className="border"
          variant="light"
          onClick={handleCancel}>
          Cancel
        </Button>
        <PrimaryButton
          buttonStyle={{ borderRadius: '8px' }}
          label="Save"
          onClick={handleSaveDocument}
          disabled={!documentName?.trim()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AddDocumentModal;
